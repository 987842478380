import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import Section from "../components/section/section";

class CustomerSupport extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Customer Support at Therapetic | Therapetic'
    this.seo = {
      title: title,
      description: '',
      seoTitle: title,
    }
  }

  render() {
    return (
      <Layout containerClassNames={`page page-customer-support`}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <Section className="customer-support py-2 px-0">
          <div className="row">
            <div className='col-10 col-md-6 mt-5 mx-auto my-md-5'>
              <h1 className="text-lg-center text-md-left">Customer Support</h1>
              <br/>
              <h2>Have a question about Therapetic or emotional support animals in general?</h2>
              <br/>
              <p>Live support is available from Monday to Friday, 9 AM to 5 PM EST.</p>
              <br/>
              <p>Email: <a href="mailto:support@therapetic.org">support@therapetic.org</a></p>
              <br/>
              <p>Phone: Please call us at <a href="tel:+8772070561">(877) 207-0561</a>.</p>
              <p>Phone support available on Monday to Friday, 9 AM to 5 PM EST.</p>
             </div>
          </div>
        </Section>
      </Layout>
    )
  }
}

export default CustomerSupport
